export const FirebaseConfig = {
	"projectId": "oyamove-f2378",
	"appId": "1:50651418314:web:618463a3823db2ed3e6e33",
	"databaseURL": "https://oyamove-f2378-default-rtdb.firebaseio.com",
	"storageBucket": "oyamove-f2378.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBr5Cgy9XIKG7dka1LZx3Vd24LfeEeZi5U",
	"authDomain": "oyamove-f2378.firebaseapp.com",
	"messagingSenderId": "50651418314",
	"measurementId": "G-GJP0WLWMTR"
};